import React from "react";
import Newsletter from "../../../components/newsletter";
import SectionTitle from "../../../components/section-title";

const NewsletterArea = () => {
    return (
        <div className="news-letter-section section-pb">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-9 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            subTitle="NEWSLETTER"
                            title="Recevez toute nos actualités en vous
                            <br class='d-none d-xl-block' />abonnant à notre newsletter"
                        />
                    </div>
                    <div className="col-xl-8 col-lg-10 mx-auto">
                        <Newsletter mailchimpUrl="https://gmail.us21.list-manage.com/subscribe/post?u=e456dbb4f2d34b1d05381a488&amp;id=ea8102891a&amp;f_id=00eec9e1f0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsletterArea;
