import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import axios from "axios";

const CustomForm = () => {
    let email;
    const [data, setData] = useState({
        email: "",
    });
    const [Statut, setStatut] = useState("");
    const url = "http://127.0.0.1:8000/api/Email/add";
    const submit = (e) => {
        e.preventDefault();
        validateEmail(data.email);
        let emailInput = document.getElementById("mc-email");
        emailInput.value = "";
    };
    // Change Handaler
    const inputChangedHandler = (e) => {
        setData({
            email: e.target.value,
        });
    };
    const validateEmail = (email) => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result === true) {
            axios
                .post(url, {
                    email: data.email,
                })
                .then(setStatut("sent"))
                .catch((error) => {
                    alert(
                        "Une erreur est survenue, veuillez réessayer plus tard"
                    );
                });
        } else {
            alert("Veuillez entrer une adresse e-mail valide");
        }
    };
    return (
        <Fragment>
            <form className="news-letter-form d-flex">
                <input
                    id="mc-email"
                    className="form-control"
                    type="email"
                    placeholder="Entrer votre Email"
                    name="mail"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    onChange={(e) => inputChangedHandler(e)}
                    ref={(node) => (email = node)}
                />
                <button className="search-btn" type="submit" onClick={submit}>
                    Subscribe
                </button>
            </form>
            {Statut === "sent" && (
                <div style={{ color: "#2ecc71", fontSize: "12px" }}>
                    Votre demande pour recevoir nos actualités a bien été reçue.
                </div>
            )}
            {/* {status === "error" && (
                <div
                    style={{ color: "#e74c3c", fontSize: "12px" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "#2ecc71", fontSize: "12px" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )} */}
        </Fragment>
    );
};

CustomForm.propTypes = {
    status: PropTypes.oneOf(["sending", "error", "success"]),
    message: PropTypes.string,
    onValidated: PropTypes.func,
};
const Newsletter = (props) => {
    return (
        <div>
            <CustomForm />
        </div>
    );
};

Newsletter.propTypes = {
    mailchimpUrl: PropTypes.string,
};

export default Newsletter;
