import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    const [data, setData] = useState({
        nom: "",
        email: "",
        description: "",
    });
    const url = "http://127.0.0.1:8000/api/ContactUs/send/email/";
    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post(url, {
                nom: data.nom,
                email: data.email,
                description: data.description,
            })
            .then()
            .catch((error) => {
                alert("Une erreur est survenue, veuillez réessayer plus tard");
            });
        let NameInput = document.getElementById("name");
        NameInput.value = "";
        let EmailInput = document.getElementById("email");
        EmailInput.value = "";
        let DescriptionInput = document.getElementById("message");
        DescriptionInput.value = "";
    };
    const EmailValidationText = `Veuillez entrer une adresse e-mail valide`;
    return (
        <Fragment>
            <form id="contactForm" className="row" onSubmit={handleSubmit}>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="nom"
                        placeholder="Nom"
                        onChange={(e) =>
                            setData({ ...data, nom: e.target.value })
                        }
                        ref={register({ required: "Ce champ est obligatoire" })}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Ton Email*"
                        onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                        }
                        ref={register({
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Email invalide",
                            },
                        })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="description"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        onChange={(e) =>
                            setData({ ...data, description: e.target.value })
                        }
                        ref={register({
                            required: "Ce champ est obligatoire",
                        })}
                    ></textarea>
                    {errors.message && <p>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-success"
                        data-complete-text="Well Done!"
                    >
                        Envoyer
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
